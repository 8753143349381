import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Subscribe from "../components/Subscribe"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { InlineEquation } from "../components/MathEquation"

const components = {
  code: InlineEquation,
}

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { ogImage } = post.frontmatter
  const ogImagePath = ogImage && ogImage.childImageSharp.fixed.src

  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        ogImagePath={ogImagePath}
      />
      <article itemScope itemType="http://schema.org/Article">
        <header className="mw-reading center mt5">
          <time className="c-secondary">{post.frontmatter.date}</time>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
        </header>
        <MDXProvider components={components}>
          <section className="blog-post" itemProp="articleBody">
            <MDXRenderer>{post.body}</MDXRenderer>
          </section>
        </MDXProvider>
      </article>

      <nav className="pt5 mw-reading center">
        <ul
          style={{
            display: `flex`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li className="w-50">
            {previous && (
              <div className="h-100 pa3 ba b--border">
                <span className="db"> ← Anterior</span>
                <Link to={previous.fields.slug} rel="prev">
                  {previous.frontmatter.title}
                </Link>
              </div>
            )}
          </li>
          <li className="w-50">
            {next && (
              <div className="h-100 pa3 ba b--border">
                <span className="db">Próximo →</span>
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title}
                </Link>
              </div>
            )}
          </li>
        </ul>
      </nav>

      <Subscribe />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY", locale: "pt-br")
        description
        ogImage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
