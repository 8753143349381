import React from "react"

const Subscribe = () => {
  return (
    <footer className="mw-reading center pv4 bt mt5">
      <h2>Inscreva-se</h2>
      <p>Seja notificado sobre novos artigos e novidades do Estruto.</p>
      <form
        action="https://buttondown.email/api/emails/embed-subscribe/estruto"
        method="post"
        target="popupwindow"
        onSubmit={() =>
          window.open("https://buttondown.email/estruto", "popupwindow")
        }
      >
        <label className="db mb1" htmlFor="email">
          Email
        </label>
        <div className="flex flex-wrap flex-nowrap-ns justify-end items-start">
          <input
            className="ph2 min-h-tap outline-0 ba b--primary focus w-100 mb2"
            type="email"
            name="email"
            id="email"
            placeholder="email@email.com"
          />
          <input type="hidden" value="1" name="embed" />
          <button
            className="min-h-tap ph3 ba b--primary outline-0 bg-primary c-background nowrap ml2 pointer"
            type="submit"
          >
            Inscrever-se
          </button>
        </div>
      </form>
    </footer>
  )
}

export default Subscribe
